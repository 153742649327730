import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { User } from '../../../auth/interfaces/response-login.interface';

import { AuthService } from '../../../auth/services/auth.service';
import { UtilidadesService } from '../../../shared/services/utilidades.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: [
  ]
})
export class HomeComponent implements OnInit {

  login: boolean = true;
  loading: boolean = true;
  user: User = this.authService.user;
  active1: boolean = true;
  active2: boolean = true;
  active3: boolean = true;
  active4: boolean = true;
  active5: boolean = true;

  constructor(private authService: AuthService,
              private utilidadesService: UtilidadesService,
              private router: Router) { }

  ngOnInit(): void {
    this.authService.validarLogin();

    if(this.user.profile_id === 3){
      this.active1 = true;
      this.active2 = true;
      this.active3 = true;
      this.active4 = true;
      this.active5 = true;
    }
  }

  irPagina(url: string, id: string): void {
    this.router.navigate(['/', url], {
      queryParams: {
        id: this.utilidadesService.encriptar(id)
      }
    });
  }
}
