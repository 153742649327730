<app-menu [login]="login" [nombres]="user != null ? user.names : ''"
    [perfil]="user != null ? user.profile_name : ''"></app-menu>

<app-loading *ngIf="loading"></app-loading>



<div class="container container-info-home">
  <div class="row">
      <div class="col-md-12 text-center mt-4 mb-4">
          <h1>CREATE ACCOUNT</h1>
      </div>
      <div class="col-md-6 offset-md-3" *ngIf="user.profile_id === 1">
        <div class="container-modulo-home text-center">
          <form [formGroup]="regionFormGroup" style="width:100%;">
            <mat-form-field appearance="fill">
              <mat-label>Name</mat-label>
              <input matInput
                      placeholder="Name"
                      formControlName="name"
                      class="w-100"/>
            </mat-form-field>
            <div>
              <button mat-raised-button color="primary" (click)="save()" class="m-2">Save</button>
              <button mat-raised-button color="primary" (click)="return()">Return</button>
            </div>
          </form>
        </div>
      </div>
  </div>
</div>

<div class="container-footer">
  <app-footer></app-footer>
</div>