import { Component, OnInit } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/auth/services/auth.service';
import { UtilidadesService } from 'src/app/shared/services/utilidades.service';
import { PageEvent } from '@angular/material/paginator';

import { environment } from 'src/environments/environment';

import { Advertiser, RequestAdvertiser, ResponseAdvertiser } from 'src/app/shared/interfaces/response-advertiser.interface';

import { AdvertiserService } from '../../services/advertiser.service';
import { User } from 'src/app/auth/interfaces/response-login.interface';

@Component({
  selector: 'app-advertiser',
  templateUrl: './advertiser.component.html',
  styleUrls: ['./advertiser.component.css']
})
export class AdvertiserComponent implements OnInit {
  loading: boolean = true;
  user: User = this.authService.user;
  login: boolean = true;
  total: number = 0;
  totalOriginal: number = 0;
  regionId: number = 0;
  regionName: string = '';
  sentenceSearch: string  = '';
  advertisers: Advertiser[] = [];
  advertisersOriginal: Advertiser[] = [];
  advertisersFilters:Advertiser[] = [];
  dataSource = new MatTableDataSource(this.advertisers);
  displayedColumns: string[] = ['name', 'status', 'actions'];
  baseUrl: string = environment.baseUrl;

  constructor(private authService: AuthService,
              private advertiserService: AdvertiserService,
              private route: ActivatedRoute,
              private router: Router,
              private utilidadesService: UtilidadesService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.authService.validarLogin();
      if(this.user.profile_id === 2) {
        this.utilidadesService.mostrarModal('Error', `Forbidden view to profile selected`);
        this.utilidadesService.goHome();
        this.return;
      }

      if(this.user.profile_id === 1) {
        this.regionId = Number(this.utilidadesService.desencriptar(params["id"]));
        this.regionName = params["name"];
      } else {
        this.regionId = this.user.region_id;
        this.regionName = this.user.region_name;
      }

      console.log("user", this.user);
      this.getData(0, 10);
    });

  }

  obtenerDataPagina(event: PageEvent): PageEvent {
    this.getData(event.pageIndex, event.pageSize);
    return event;
  }

  getData(offset: number, limit: number): void {
    this.advertiserService.getAdvertiser(this.regionId, offset, limit).subscribe({
      next: (response: ResponseAdvertiser) => {
        this.advertisers = response.result;
        this.advertisersOriginal = response.result;
        this.total = response.total;
        this.totalOriginal = response.total;
        this.dataSource = new MatTableDataSource(this.advertisers);
        this.loading = false;
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load data.`);
      }
    });
  }

  add(id: number): void {
    this.router.navigate(['/', 'advertiser-create'], {
      queryParams: {
        id: this.utilidadesService.encriptar(id.toString()),
        region_id: this.utilidadesService.encriptar(this.regionId.toString()),
        name: this.regionName,
      }
    });
  }

  inactive(id: number): void {
    this.loading = true;
    const request: RequestAdvertiser = {
      name: '',
      id: id,
      region_id: this.regionId,
    };

    this.advertiserService.inactiveAdvertiser(request).subscribe({
      next: () => {
        this.getData(0, 10);
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error save data.`);
      }
    });
  }

  search(){
    this.advertisersFilters = [];

    if(this.sentenceSearch.length === 0) {
      this.total = this.totalOriginal;
      this.dataSource = new MatTableDataSource(this.advertisersOriginal);
      this.return;
    }

    if(this.sentenceSearch.trim().length > 3){
      this.loading = true;
      this.advertiserService.getAdvertiserFilter(this.regionId, this.sentenceSearch.toLowerCase().trim()).subscribe({
        next: (response: ResponseAdvertiser) => {
          //this.advertisersFilters = response.result;
          this.advertisers = response.result;
          this.total = response.total;
          this.dataSource = new MatTableDataSource(this.advertisers);
          this.loading = false;
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Not found.`);
        }});
    }
  }

  selectedOption( event: MatAutocompleteSelectedEvent) {
    if(!event.option.value)
      return;

    const data: Advertiser = event.option.value;
    this.go(data.id);
  }

  go(id: number): void {
    this.router.navigate(['/', 'advertiser-create'], {
      queryParams: {
        id: this.utilidadesService.encriptar(id.toString()),
        region_id: this.utilidadesService.encriptar(this.regionId.toString()),
        name: this.regionName,
      }
    });
  }

  goCampaigns(id: number, name: string): void {
    this.router.navigate(['/', 'campaigns'], {
      queryParams: {
        advertiser_id: this.utilidadesService.encriptar(id.toString()),
        name: name
      }
    });
  }

  return(): void{
    this.router.navigate(['/', 'region']);
  }

  home(): void{
    this.utilidadesService.goHome();
  }
}
