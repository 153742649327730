<app-menu [login]="login" [nombres]="user != null ? user.names : ''"
    [perfil]="user != null ? user.profile_name : ''"></app-menu>

<app-loading *ngIf="loading"></app-loading>

<div class="container container-info-home">
  <div class="row">
      <div class="col-md-12 text-center mt-4 mb-4">
          <h1>CREATE USER</h1>
      </div>
      <div class="col-md-6 offset-md-3">
        <div class="container-modulo-home text-center">
          <form [formGroup]="regionFormGroup" style="width:100%;">
            <mat-form-field appearance="fill">
              <mat-label>Names</mat-label>
              <input matInput
                      placeholder="Name"
                      formControlName="names"
                      class="w-100"/>
            </mat-form-field>
            <br/>
            <mat-form-field appearance="fill">
              <mat-label>Identification</mat-label>
              <input matInput
                      placeholder="Identification"
                      formControlName="identification"
                      class="w-100"/>
            </mat-form-field>
            <br/>
            <mat-form-field appearance="fill">
              <mat-label>Email</mat-label>
              <input matInput
                      type="email"
                      placeholder="email"
                      formControlName="email"
                      class="w-100"/>
            </mat-form-field>
            <br/>
            <mat-form-field appearance="fill">
              <mat-label>Password</mat-label>
              <input matInput
                      type="password"
                      placeholder="pass"
                      formControlName="pass"
                      class="w-100"/>
            </mat-form-field>
            <br/>
            <mat-form-field appearance="fill">
              <mat-label>Region</mat-label>
              <select matNativeControl formControlName="region_id">
                  <option value="" selected></option>
                  <option *ngFor="let region of regions" [value]="region.id">
                      {{region.name}}
                  </option>
              </select>
            </mat-form-field>
            <br/>
            <mat-form-field appearance="fill">
              <mat-label>Profile</mat-label>
              <select matNativeControl formControlName="profile_id">
                  <option value="" selected></option>
                  <option value="1" *ngIf="user.profile_id === 1">Super Admin</option>
                  <option value="3">Admin</option>
                  <option value="2">Client</option>
              </select>
            </mat-form-field>
            <div>
              <button mat-raised-button color="primary" (click)="save()" class="m-2">Save</button>
              <button mat-raised-button color="primary" (click)="return()">Return</button>
            </div>
          </form>
        </div>
      </div>
  </div>
</div>

<div class="container-footer">
  <app-footer></app-footer>
</div>
