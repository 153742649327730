import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { User } from 'src/app/auth/interfaces/response-login.interface';

import { AuthService } from 'src/app/auth/services/auth.service';
import { UtilidadesService } from 'src/app/shared/services/utilidades.service';
import { PageEvent } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { Region, RequestRegion, ResponseRegion } from 'src/app/shared/interfaces/response-region.interface';
import { RegionService } from '../../services/region.service';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.css']
})
export class RegionComponent implements OnInit {
  loading: boolean = true;
  user: User = this.authService.user;
  login: boolean = true;
  total: number = 0;
  sentenceSearch: string  = '';
  regions: Region[] = [];
  regionsFilters:Region[] = [];
  dataSource = new MatTableDataSource(this.regions);
  displayedColumns: string[] = ['name', 'status', 'actions'];
  baseUrl: string = environment.baseUrl;

  constructor(private authService: AuthService,
              private regionService: RegionService,
              private router: Router,
              private utilidadesService: UtilidadesService) { }

  ngOnInit(): void {
    this.authService.validarLogin();
    if(this.user.profile_id > 1) {
      this.utilidadesService.mostrarModal('Error', `Forbidden view to profile selected`);
      this.utilidadesService.goHome();
      this.return;
    }

    this.getData(0, 10);
  }

  obtenerDataPagina(event: PageEvent): PageEvent {
    this.getData(event.pageIndex, event.pageSize);
    return event;
  }

  getData(offset: number, limit: number): void {
    this.regionService.getRegion(offset, limit).subscribe({
      next: (response: ResponseRegion) => {
        this.regions = response.result;
        this.total = response.total;
        this.dataSource = new MatTableDataSource(this.regions);
        this.loading = false;
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load data.`);
      }
    });
  }

  add(id: number): void {
    this.router.navigate(['/', 'region-create'], {
      queryParams: {
        id: this.utilidadesService.encriptar(id.toString())
      }
    });
  }

  goAdvertiser(id: number, name: string): void {
    this.router.navigate(['/', 'advertiser'], {
      queryParams: {
        id: this.utilidadesService.encriptar(id.toString()),
        name: name
      }
    });
  }

  inactive(id: number): void {
    this.loading = true;
    const request: RequestRegion = {
      name: '',
      id: id,
    };

    this.regionService.inactiveRegion(request).subscribe({
      next: () => {
        this.getData(0, 10);
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error save data.`);
      }
    });
  }

  return(): void{
    this.utilidadesService.goHome();
  }
}
