import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { UtilidadesService } from '../../../shared/services/utilidades.service';

import { ResponseLogin, User } from '../../interfaces/response-login.interface';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [
  ]
})
export class LoginComponent implements OnInit {

  loading:boolean = true;
  user: User = this.authService.user;
  show: boolean = true;
  formularioAdicionar:UntypedFormGroup = this.fb.group({
    usuario: [ , [ Validators.required ]   ],
    clave: [ , [ Validators.required ]   ]
  });

  constructor(private router: Router,
              private authService: AuthService,
              private utilidadesService: UtilidadesService,
              private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.show = true;
    if(this.user != null){
      this.router.navigate(['/', 'home']);
    } else {
      localStorage.clear();
    }
  }

  validateField(campo: string): boolean | null{
    return this.utilidadesService.campoEsValido(this.formularioAdicionar, campo);
  }

  login(): void{
    if ( this.formularioAdicionar.invalid )  {
      this.formularioAdicionar.markAllAsTouched();
      this.utilidadesService.mostrarModal('Error', `All fields are required.`);
      return;
    }

    this.loading = true;

    const usuario: string = this.formularioAdicionar.get('usuario')?.value;
    let pwd: string = this.formularioAdicionar.get('clave')?.value;

    this.authService.loginUser(usuario, pwd).subscribe({
      next: (response: ResponseLogin) => {
        localStorage.setItem('data', this.utilidadesService.encriptar(JSON.stringify(response.user[0])));
        localStorage.setItem('token', this.utilidadesService.encriptar(JSON.stringify(response.token)));
        this.router.navigate(['/', 'home'], {
          queryParams: { seccion: this.utilidadesService.encriptar("1"),
                        curso: this.utilidadesService.encriptar("1")}});
      },
      error: (error: any) => {
        this.utilidadesService.mostrarModal('Error', `Error to search the user.`);
      }
    });
  }

  onVideoEnded(): void {
    this.show = false;
  }
}
