<header>
	<div class="container">
		<div class="row">
			<div class="col-md-3">
				<img src="assets/img/logo_GMNCS.svg" class="logo-header">
			</div>			
			<div class="col-md-4 offset-md-4 mt-2 text-end">
				<div *ngIf="login">
					<!--<h2>WELCOME:</h2>--><span> {{ nombres }} ({{ perfil }})</span>
					<br>
					<button (click)="logout()" class="btn btn-primary btn-header">Log Out</button>
				</div>
			</div>
		</div>
	</div>
</header>
