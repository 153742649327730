  <footer>
    <div class="container">
      <div class="row"> 
        <div class="col-md-12 text-center mt-3">
          <img src="assets/img/logoNexus.svg" class="logo-footer">
        </div>
        <div class="col-md-12 text-center mt-3">
          ©Nexus Creative Solution {{ currentYear }}. All rights reserved.
        </div>  
      </div>
    </div>
  </footer>	
