<app-menu [login]="login" [nombres]="user != null ? user.names : ''"
    [perfil]="user != null ? user.profile_name : ''"></app-menu>

<app-loading *ngIf="loading"></app-loading>

<div class="container container-info-home">
  <div class="row">
      <div class="col-md-12 text-center mt-4 mb-4">
          <h1>CREATE ADVERTISER</h1>
      </div>
      <div class="col-md-8 offset-md-3">
        <div class="container-modulo-home text-center">
          <form [formGroup]="advertiserFormGroup">
            <div class="row">
              <div class="col-md-12">
                <mat-form-field appearance="fill">
                  <mat-label>Name</mat-label>
                  <input matInput
                          placeholder="Name"
                          formControlName="name"/>
                </mat-form-field>
                <br/>
                <mat-form-field appearance="fill">
                  <mat-label>Region</mat-label>
                  <select matNativeControl formControlName="region_id">
                      <option value="" selected></option>
                      <option *ngFor="let region of regions" [value]="region.id">
                          {{region.name}}
                      </option>
                  </select>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 card p-4">
                <mat-form-field appearance="fill" class="w-100">
                <mat-label>Type the user name</mat-label>
                <input type="text"
                  matInput
                  placeholder="Type"
                  formControlName="sentenceSearch"
                  [matAutocomplete]="auto"
                  (input)="search()" />
                <mat-autocomplete autoActiveFirstOption
                  #auto="matAutocomplete"
                  (optionSelected)="selectedOption( $event )">
                <mat-option *ngFor="let user of advertiserUsersFilter" [value]="user">
                  <span>{{ user.names | titlecase }}</span> |
                  <span>{{ user.email | titlecase }}</span> |
                  <small> Status: {{ user.status | status }}</small>
                </mat-option>
                </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 card p-4">
                <table mat-table [dataSource]="dataSource" class="w-100">

                  <ng-container matColumnDef="identification">
                    <th mat-header-cell *matHeaderCellDef> Identification</th>
                    <td mat-cell *matCellDef="let element"> {{element.identification}} </td>
                  </ng-container>

                  <ng-container matColumnDef="names">
                    <th mat-header-cell *matHeaderCellDef> Name</th>
                    <td mat-cell *matCellDef="let element"> {{element.names}} </td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> Email</th>
                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element">
                      <div [className]="element.status === 'A' ? 'rounded-div-green' : 'rounded-div-red'">
                        {{element.status | status }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> Actions </th>
                  <td mat-cell *matCellDef="let element">
                  <button mat-raised-button
                    class="ms-2"
                    (click)="remove(element.id)"
                    color="primary">
                    Delete
                  </button>
                  </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 card p-4">
                <div fxLayout="row" class="text-center {{ loading === true ? 'd-none' : '' }}">
                <mat-paginator #paginator
                    [length]="total"
                    pageSize="10"
                    (page)="obtenerDataPagina($event)"
                    [pageSizeOptions]="[10, 20, 30]"
                    showFirstLastButtons="true"
                    aria-label="Select page">
                </mat-paginator>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-4 text-center">
                <button mat-raised-button color="primary" (click)="save()" class="m-2">Save</button>
                <button mat-raised-button color="primary" (click)="return()">Return</button>
              </div>
            </div>
          </form>
        </div>
      </div>
  </div>
</div>

