import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  getDataOrigin(path: string): Observable<any> {
    return this.http.get(path, { responseType: 'text' });
  }

  getColumns(data: string): any {
    let columns: any[] = [];
    const dynamicContent = data.split('var devDynamicContent = {};')[1].split('Enabler.setDevDynamicContent(devDynamicContent);')[0];
    let dataColumns = dynamicContent.split(';');
    dataColumns.slice(1).forEach(line  => {
      const field = this.getPath(line, columns);
      columns.push(field);
    });

    columns.shift();
    console.log(`columns`, columns);
    return columns;
  }

  getPath(line: string, columns: any[]): string {

    const match = line.match(/(\w+(\.\w+)*)(\s*=\s*(.*))/);

      if (match && match.length > 2) {
        let field = match[1].trim();
        let value = match[4] ? match[4].trim() : '';

        if (field.includes('.')) {
          const parts = field.split('.');
          let key = parts[parts.length - 1];
          let path = parts.slice(0, -1).join('.');
          let existingObj = columns.find(col => col.field === path);
          if (existingObj) {
            existingObj.value[key] = value;
          } else {
            let obj: any = {};
            obj[key] = value;
            return `${ path }.${ parts[1] }`;
          }
        } else {
          return field;
        }
    }

    return "";
  }
}
