<h2 mat-dialog-title>{{ data.titulo }}</h2>
<mat-dialog-content class="mat-typography">
  <h3>{{ data.texto }}</h3>
  <p></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Cancel</button>
  <button mat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Confirm</button>
</mat-dialog-actions>

