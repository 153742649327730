import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { User } from 'src/app/auth/interfaces/response-login.interface';

import { AdvertiserService } from 'src/app/admon/services/advertiser.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UtilidadesService } from 'src/app/shared/services/utilidades.service';

import { Advertiser, AdvertiserUser, AdvertiserUserRelation, RequestAdvertiser, RequestAdvertiserUser, ResponseAdvertiser, ResponseAdvertiserCreated, ResponseAdvertiserUser } from 'src/app/shared/interfaces/response-advertiser.interface';
import { RegionService } from 'src/app/admon/services/region.service';
import { Region, ResponseRegion } from 'src/app/shared/interfaces/response-region.interface';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-create-advertiser',
  templateUrl: './create-advertiser.component.html',
  styleUrls: ['./create-advertiser.component.css']
})
export class CreateAdvertiserComponent {
  loading: boolean = true;
  user: User = this.authService.user;
  login: boolean = true;
  id: number = 0;
  total: number = 0;
  region_id: number = 0;
  region_name: string = '';
  advertiser: Advertiser = null!;
  regions: Region[] = [];
  advertiserUsers: AdvertiserUser[] = [];
  advertiserUsersFilter: AdvertiserUser[] = [];
  advertiserFormGroup = this.fb.group({
    name: ['', Validators.required],
    region_id: ['', Validators.required],
    sentenceSearch: [''],
  });
  dataSource = new MatTableDataSource(this.advertiserUsers);
  displayedColumns: string[] = ['identification', 'names', 'email', 'status', 'actions'];

  constructor(private authService: AuthService,
              private advertiserService: AdvertiserService,
              private regionService: RegionService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private utilidadesService: UtilidadesService) { }

    ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
        this.authService.validarLogin();
        if(this.user.profile_id === 2) {
          this.utilidadesService.mostrarModal('Error', `Forbidden view to profile selected`);
          this.utilidadesService.goHome();
          this.return;
        }

        this.id = Number(this.utilidadesService.desencriptar(params["id"]));
        this.region_id = Number(this.utilidadesService.desencriptar(params["region_id"]));
        this.region_name = params["name"];

        this.regionService.getRegion(0, 1000).subscribe({
          next: (response: ResponseRegion) => {
            this.regions = response.result;
          },
          error: () => {
            this.utilidadesService.mostrarModal('Error', `Error load data.`);
          }
        });

        if(this.id > 0) {
          this.getData();
        } else {
          this.loading = false;
        }
      });
    }

    getData(): void {
      this.advertiserService.getAdvertiserByID(Number(this.id)).subscribe({
        next: (response: ResponseAdvertiser) => {
          this.advertiser = response.result[0];
          this.advertiserFormGroup.setControl('name', new FormControl(this.advertiser.name));
          this.advertiserFormGroup.setControl('region_id', new FormControl(this.advertiser.region_id));
          this.getUserAdvertiser(0, 10);
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Error load data.`);
          this.loading = false;
        }
      });
    }

    obtenerDataPagina(event: PageEvent): PageEvent {
      this.getUserAdvertiser(event.pageIndex, event.pageSize);
      return event;
    }

    getUserAdvertiser(offset: number, limit: number): void {
      this.advertiserService.getAdvertiserByUser(this.advertiser.id, offset, limit).subscribe({
        next: (response: ResponseAdvertiserUser) => {
          this.advertiserUsers = response.result;
          this.total = response.total;
          this.dataSource = new MatTableDataSource(this.advertiserUsers);
          this.loading = false;
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Error load data.`);
          this.loading = false;
        }
      });
    }

    search(){
      this.advertiserUsersFilter = [];
      const sentenceSearch: string = this.advertiserFormGroup.get('sentenceSearch')?.value!

      if(sentenceSearch.trim().length > 3){
        this.loading = true;
        this.advertiserService.getAdvertiserByUserFilter(sentenceSearch.toLowerCase().trim()).subscribe({
          next: (response: ResponseAdvertiserUser) => {
            this.advertiserUsersFilter = response.result;
            this.loading = false;
          },
          error: () => {
            this.utilidadesService.mostrarModal('Error', `Not found.`);
            this.loading = false;
          }});
      }
    }

    selectedOption( event: MatAutocompleteSelectedEvent) {
      if(!event.option.value)
        return;

      const data: AdvertiserUser = event.option.value;
      console.log(data);

      this.advertiserUsers.push(data);
      this.advertiserUsers = Array.from(
        new Map(this.advertiserUsers.map(item => [item.id, item])).values()
      );

      this.total = this.advertiserUsers.length;
      this.dataSource = new MatTableDataSource(this.advertiserUsers);
      this.advertiserFormGroup.setControl('sentenceSearch', new FormControl(data.names));
    }

    remove(id: number): void {
      this.advertiserUsers = this.advertiserUsers.filter(user => user.id !== id);
      this.total = this.advertiserUsers.length;
      this.dataSource = new MatTableDataSource(this.advertiserUsers);
    }

    save(): void {
      if ( this.advertiserFormGroup.invalid )  {
        this.advertiserFormGroup.markAllAsTouched();
        this.utilidadesService.mostrarModal('Error', `Name and region is required.`);
        return;
      }

      this.loading = true;
      const name: string = this.advertiserFormGroup.get('name')?.value!;
      const region_id: number = Number(this.advertiserFormGroup.get('region_id')?.value!);
      const request: RequestAdvertiser = {
        id: this.id,
        name: name,
        region_id: region_id,
      };

      this.advertiserService.addAdvertiser(request).subscribe({
        next: (response: ResponseAdvertiserCreated) => {
          this.id = response.result;
          this.addAdvertiserUser();
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Error load data.`);
        }
      });
    }

    addAdvertiserUser(): void {

      let users: AdvertiserUserRelation[] = [];
      this.advertiserUsers.forEach( user => users.push({user_id: user.id, advertiser_id: this.id}));

      const request: RequestAdvertiserUser = {
        advertiser_id: this.id,
        users: users
      };

      this.advertiserService.addAdvertiserUser(request).subscribe({
        next: () => {
          this.getData();
          this.utilidadesService.mostrarModal('Info', `Save data succesfully.`);
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Error load data.`);
        }
      });
    }

    return(): void{
      this.router.navigate(['/', 'advertiser'], {
        queryParams: {
          id: this.utilidadesService.encriptar(this.region_id.toString()),
          name: this.region_name,
        }
      });
    }
}
