import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { FormatComments, ResponseCampaignFormatsComments, ResponseFormatsComments } from 'src/app/shared/interfaces/response-campaign-formats-comments.interface';
import { RequestCampaignFilter, ResponseCampaignFilter, ResponseCampaignFilterTotal } from 'src/app/shared/interfaces/response-campaign-filters.interface';
import { RequestCampaign, ResponseCampaign, ResponseCampaignCreated } from 'src/app/shared/interfaces/response-campaign.interface';
import { RequestCampaignData, ResponseCampaignData } from 'src/app/shared/interfaces/response-campaign-data.interface';
import { RequestCampaignDeta, ResponseCampaignDeta } from 'src/app/shared/interfaces/response-campaign-deta.interface';
import { RequestCampaignFormat, ResponseCampaignFormats } from 'src/app/shared/interfaces/response-campaign-formats.interface';

import { AuthService } from 'src/app/auth/services/auth.service';
import { RequestCampaignDetaHtml, ResponseCampaignDetaHtml } from 'src/app/shared/interfaces/response-campaign-deta-html.interface';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  private _baseApi = environment.baseApiVariations;

  constructor(private http: HttpClient,
              private authService: AuthService) { }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.authService.token
      })
  };

  private getHeadersForm(): HttpHeaders {
    return new HttpHeaders(
      {
        'Authorization': 'Bearer ' + this.authService.token,
        'Accept': '*/*'
      })
  };

  getCampaigns(advertiserId: number, offset: number, limit: number): Observable<ResponseCampaign>{
    let headers = this.getHeaders();
    return this.http.get<ResponseCampaign>(`${ this._baseApi }/api/v1/campaigns?advertiser_id=${advertiserId}&offset=${offset}&limit=${limit}`, { headers });
  }

  getCampaignsFilter(advertiserId: number, name: string): Observable<ResponseCampaign>{
    return this.http.get<ResponseCampaign>(`${ this._baseApi }/api/v1/campaigns-filter?advertiser_id=${advertiserId}&name=${name}`);
  }

  getCampaignByID(campaignId: number): Observable<ResponseCampaign>{
    return this.http.get<ResponseCampaign>(`${ this._baseApi }/api/v1/campaigns-id?campaign_id=${campaignId}`);
  }

  getCampaignFormats(campaignId: string): Observable<ResponseCampaignFormats>{
    return this.http.get<ResponseCampaignFormats>(`${ this._baseApi }/api/v1/campaigns-formats?campaign_id=${campaignId}`);
  }

  getCampaignFormatsByIDs(id: string): Observable<ResponseCampaignFormats>{
    let headers = this.getHeaders();
    return this.http.get<ResponseCampaignFormats>(`${ this._baseApi }/api/v1/campaigns-formats-id?id=${id}`, { headers });
  }

  getCampaignFilters(campaignId: string): Observable<ResponseCampaignFilter>{
    return this.http.get<ResponseCampaignFilter>(`${ this._baseApi }/api/v1/campaigns-filters?campaign_id=${campaignId}`);
  }

  getCampaignFiltersTotal(campaignId: number): Observable<ResponseCampaignFilterTotal>{
    let headers = this.getHeaders();
    return this.http.get<ResponseCampaignFilterTotal>(`${ this._baseApi }/api/v1/campaigns-filters-total?campaign_id=${campaignId}`, { headers });
  }

  getCampaignDeta(campaignFormatId: number): Observable<ResponseCampaignDeta>{
    return this.http.get<ResponseCampaignDeta>(`${ this._baseApi }/api/v1/campaigns-deta?campaign_id=${campaignFormatId}`);
  }

  getCampaignDetaHtml(campaignFormatId: number): Observable<ResponseCampaignDetaHtml>{
    let headers = this.getHeaders();
    return this.http.get<ResponseCampaignDetaHtml>(`${ this._baseApi }/api/v1/campaigns-deta-html?campaign_id=${campaignFormatId}`, { headers });
  }

  getCampaignData(campaignId: string, filters: string): Observable<ResponseCampaignData>{
    return this.http.get<ResponseCampaignData>(`${ this._baseApi }/api/v1/campaigns-data?campaign_id=${campaignId}&filters=${filters}`);
  }

  getCampaignDataById(id: string): Observable<ResponseCampaignData>{
    let headers = this.getHeaders();
    return this.http.get<ResponseCampaignData>(`${ this._baseApi }/api/v1/campaigns-data-id?id=${id}`, { headers });
  }

  getCampaignFormatsCommentsByIDs(id: number): Observable<ResponseCampaignFormatsComments>{
    return this.http.get<ResponseCampaignFormatsComments>(`${ this._baseApi }/api/v1/campaigns-formats-comments-id?id=${id}`);
  }

  addCampaignFormatsComments(request: FormatComments): Observable<ResponseFormatsComments>{
    let headers = this.getHeaders();
    return this.http.post<ResponseFormatsComments>(`${ this._baseApi }/api/v1/campaigns-formats-comments-id`, request, { headers });
  }

  updateCampaignFormatsComments(request: FormatComments): Observable<ResponseFormatsComments>{
    let headers = this.getHeaders();
    return this.http.put<ResponseFormatsComments>(`${ this._baseApi }/api/v1/campaigns-formats-comments-id`, request, { headers });
  }

  addCampaign(request: RequestCampaign): Observable<ResponseCampaignCreated>{
    let headers = this.getHeaders();
    return this.http.post<ResponseCampaignCreated>(`${ this._baseApi }/api/v1/campaigns`, request, { headers });
  }

  inactiveCampaign(request: RequestCampaign): Observable<ResponseCampaignCreated>{
    let headers = this.getHeaders();
    return this.http.put<ResponseCampaignCreated>(`${ this._baseApi }/api/v1/campaigns/inactivate`, request, { headers });
  }

  addCampaignFilters(request: RequestCampaignFilter): Observable<ResponseCampaignCreated>{
    let headers = this.getHeaders();
    return this.http.post<ResponseCampaignCreated>(`${ this._baseApi }/api/v1/campaigns-filters`, request, { headers });
  }

  addCampaignData(request: RequestCampaignData): Observable<ResponseCampaignCreated>{
    let headers = this.getHeaders();
    return this.http.post<ResponseCampaignCreated>(`${ this._baseApi }/api/v1/campaigns-data`, request, { headers });
  }

  addCampaignDeta(request: RequestCampaignDeta): Observable<ResponseCampaignDeta>{
    let headers = this.getHeaders();
    return this.http.post<ResponseCampaignDeta>(`${ this._baseApi }/api/v1/campaigns-deta`, request, { headers });
  }

  addCampaignDetaHtml(request: RequestCampaignDetaHtml): Observable<ResponseCampaignDeta>{
    let headers = this.getHeaders();
    return this.http.post<ResponseCampaignDeta>(`${ this._baseApi }/api/v1/campaigns-deta-html`, request, { headers });
  }

  addCampaignFormat(request: RequestCampaignFormat): Observable<ResponseCampaignFormats>{
    let headers = this.getHeaders();
    return this.http.post<ResponseCampaignFormats>(`${ this._baseApi }/api/v1/campaigns-formats`, request, { headers });
  }

  loadFileFormatCampaign(file: File | null, pathCampaign: string, pathFormat: string):Observable<any>{
    let headers = this.getHeadersForm();
    const formData: FormData = new FormData();
    formData.append('file', file!);
    formData.append('path', pathFormat);
    formData.append('path_parent', pathCampaign);

    return this.http.post(`${ this._baseApi }/api/v1/campaigns/saveFile`, formData, { headers });
  }
}
