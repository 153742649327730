import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

import { ResponseLogin, User } from '../interfaces/response-login.interface';

import { UtilidadesService } from 'src/app/shared/services/utilidades.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _baseApi = environment.baseApiVariations;

  constructor(private http: HttpClient,
              private router: Router,
              private utilidadesService: UtilidadesService) { }

  get user(): User{
    try{
      return JSON.parse(this.utilidadesService.desencriptar(localStorage.getItem('data')!));
    } catch(ex){
      localStorage.removeItem('data');
      localStorage.removeItem('token');
      return null!;
    }
  }

  get token(): string{
    return JSON.parse(this.utilidadesService.desencriptar(localStorage.getItem('token')!));
  }

  loginUser(usuario: string, pwd: string): Observable<ResponseLogin>{
    const formData = new FormData();
    formData.append('email', usuario);
    formData.append('password', pwd);
    return this.http.post<ResponseLogin>(`${ this._baseApi }/api/v1/login`, formData);
  }

  loginAdmon(usuario: string, pwd: string): Observable<ResponseLogin>{
    const formData = new FormData();
    formData.append('user', usuario);
    formData.append('pwd', pwd);
    return this.http.post<ResponseLogin>(`${ this._baseApi }/login/ValidaUsuarioAdminApi`, formData);
  }

  logout(): void {
    localStorage.removeItem('data');
    localStorage.removeItem('numero_identificacion');
    this.router.navigate(['/', 'login']);
  }

  validarLogin(): void {
    if(this.user === null) {
      this.logout();
      this.router.navigate(['/', 'login']);
    }
  }
}
