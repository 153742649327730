import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { RequestRegion, ResponseRegion, ResponseRegionCreated } from 'src/app/shared/interfaces/response-region.interface';

import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  private _baseApi = environment.baseApiVariations;

  constructor(private http: HttpClient,
              private authService: AuthService) { }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.authService.token
      })
  };

  getRegion(offset: number, limit: number): Observable<ResponseRegion>{
    let headers = this.getHeaders();
    return this.http.get<ResponseRegion>(`${ this._baseApi }/api/v1/region?offset=${offset}&limit=${limit}`, { headers });
  }

  getRegionByID(id: number): Observable<ResponseRegion>{
    let headers = this.getHeaders();
    return this.http.get<ResponseRegion>(`${ this._baseApi }/api/v1/region-id?id=${id}`, { headers });
  }

  addRegion(request: RequestRegion): Observable<ResponseRegionCreated>{
    let headers = this.getHeaders();
    return this.http.post<ResponseRegionCreated>(`${ this._baseApi }/api/v1/region`, request, { headers });
  }

  inactiveRegion(request: RequestRegion): Observable<ResponseRegionCreated>{
    let headers = this.getHeaders();
    return this.http.put<ResponseRegionCreated>(`${ this._baseApi }/api/v1/region/inactivate`, request, { headers });
  }
}
