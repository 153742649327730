import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { Campaign, RequestCampaign, ResponseCampaign } from 'src/app/shared/interfaces/response-campaign.interface';
import { User } from 'src/app/auth/interfaces/response-login.interface';

import { AuthService } from 'src/app/auth/services/auth.service';
import { CampaignService } from 'src/app/admon/services/campaign.service';
import { UtilidadesService } from 'src/app/shared/services/utilidades.service';
import { PageEvent } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { HORIZONTAL_POSITION, VERTICAL_POSITION } from 'src/app/shared/constants';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.css']
})
export class CampaignsComponent implements OnInit {
  loading: boolean = true;
  user: User = this.authService.user;
  login: boolean = true;
  total: number = 0;
  totalOriginal: number = 0;
  advertiserID: number = 0;
  advertiserName: string = '';
  sentenceSearch: string  = '';
  campaigns: Campaign[] = [];
  campaignsOriginal: Campaign[] = [];
  campaingsFilters:Campaign[] = [];
  dataSource = new MatTableDataSource(this.campaigns);
  displayedColumns: string[] = ['name', 'date_created', 'status', 'actions'];
  baseUrl: string = environment.baseUrl;

  constructor(private authService: AuthService,
              private campaignService: CampaignService,
              private clipboard: Clipboard,
              private snackBar: MatSnackBar,
              private router: Router,
              private route: ActivatedRoute,
              private utilidadesService: UtilidadesService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if(this.user.profile_id === 2) {
        this.utilidadesService.mostrarModal('Error', `Forbidden view to profile selected`);
        this.utilidadesService.goHome();
        this.return;
      }

      this.advertiserID = Number(this.utilidadesService.desencriptar(params["advertiser_id"]));
      this.advertiserName = params["name"];
      this.getData(0, 10);
    });

  }

  obtenerDataPagina(event: PageEvent): PageEvent {
    this.getData(event.pageIndex, event.pageSize);
    return event;
  }

  getData(offset: number, limit: number): void {
    this.campaignService.getCampaigns(this.advertiserID, offset, limit).subscribe({
      next: (response: ResponseCampaign) => {
        this.campaigns = response.result;
        this.campaignsOriginal = response.result;
        this.totalOriginal = response.total;
        this.total = response.total;
        this.dataSource = new MatTableDataSource(this.campaigns);
        this.loading = false;
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load data.`);
      }
    });
  }

  search(){
    this.campaingsFilters = [];
    if(this.sentenceSearch.length === 0) {
      this.total = this.totalOriginal;
      this.dataSource = new MatTableDataSource(this.campaignsOriginal);
      this.return;
    }

    if(this.sentenceSearch.trim().length > 3){
      this.loading = true;
      this.campaignService.getCampaignsFilter(this.advertiserID, this.sentenceSearch.toLowerCase().trim()).subscribe({
        next: (response: ResponseCampaign) => {
          //this.campaingsFilters = response.result;
          this.campaigns = response.result;
          this.total = response.total;
          this.dataSource = new MatTableDataSource(this.campaigns);
          this.loading = false;
        },
        error: (error: any) => {
          this.utilidadesService.mostrarModal('Error', `Not found.`);
          this.loading = false;
        }});
    }
  }

  selectedOption( event: MatAutocompleteSelectedEvent) {
    if(!event.option.value)
      return;

    const data: Campaign = event.option.value;
    this.go(data.id);
  }

  go(id: number): void {
    const idEncr = this.utilidadesService.encriptar(id.toString());
    window.open(`/preview?id=${ idEncr }`, '_blank');
  }

  add(id: number): void {
    this.router.navigate(['/', 'campaign-create'], {
      queryParams: {
        id: this.utilidadesService.encriptar(id.toString()),
        advertiser_id: this.utilidadesService.encriptar(this.advertiserID.toString()),
        name: this.advertiserName
      }
    });
  }

  inactive(id: number): void {
    this.loading = true;
    const request: RequestCampaign = {
      name: '',
      id: id,
      advertiser_id: 0
    };

    this.campaignService.inactiveCampaign(request).subscribe({
      next: () => {
        this.getData(0, 10);
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load data.`);
      }
    });
  }

  copyToClipboard(id: number): void {
    const link: string = this.baseUrl + 'preview?id=' + this.utilidadesService.encriptar(id.toString());
    this.clipboard.copy(link);
    this.snackBar.open('¡URL copied to clipboard!','Got it', {
      duration: 2500,
      panelClass: 'snackbar',
      horizontalPosition: HORIZONTAL_POSITION,
      verticalPosition: VERTICAL_POSITION,
    });
  }

  return(): void{
    this.router.navigate(['/', 'advertiser'], {
      queryParams: {
        region_id: this.utilidadesService.encriptar(this.advertiserID.toString()),
        name: this.advertiserName,
      }
    });
  }
}
