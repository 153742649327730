<app-loading *ngIf="loading"></app-loading>
<div class="container bg-light">
    <form class="w-100" [formGroup]="formatFormGroup" autocomplete="off">
        Format
        <mat-form-field appearance="fill">
          <mat-label>Format</mat-label>
          <mat-select formControlName="formatId">
            <mat-option
              *ngFor="let format of formats"
              [value]="format.id">
              {{format.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br/><br/>
        <div *ngIf="edit">
          <mat-checkbox
              class="check-filters-preview"
              formControlName="loadFile"
              value="checkLoadFile"
              (change)="checkLoadFile()">
              Load new File
          </mat-checkbox>
        </div>
        <div *ngIf="loadFile">
          Campaign format (.zip)
          <mat-form-field appearance="fill">
            <mat-label>Load zip file</mat-label>
              <input matInput
                  [value]="fileName"
                  (click)="fileInput.click()"
                  [disabled]="headers.length === 0"
                  accept=".csv,.xlsx,.xls"
                  readonly />
              <input type="file"
                  (change)="onFileChangeHtml($event)"
                  hidden
                  #fileInput />
              <button mat-icon-button
                    matSuffix
                    [disabled]="headers.length === 0"
                    (click)="fileInput.click()">
              <mat-icon>attach_file</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <br/><br/>
        <table *ngIf="dynamicData && dynamicData.length > 0">
            <tr *ngFor="let header of headers">
                <td>
                    <input matInput [value]="header" [readonly]="true" #input>
                </td>
                <td>
                    <mat-form-field appearance="fill">
                        <mat-label>Field</mat-label>
                        <select matNativeControl [formControlName]="'filter_' + header.toLowerCase().trim()">
                            <option value="" selected></option>
                            <option *ngFor="let data of dynamicData" [value]="data">
                                {{data}}
                            </option>
                        </select>
                    </mat-form-field>
                </td>
            </tr>
        </table>
        <button mat-flat-button color="primary"
                [disabled]="!completed || loading"
                (click)="create()">
            Save
        </button>
    </form>
</div>
