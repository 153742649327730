import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../interfaces/dialogData.interface';

@Component({
  selector: 'app-dialog-show',
  templateUrl: './dialog-show.component.html',
  styles: [
  ]
})
export class DialogShowComponent {

  constructor( @Inject(MAT_DIALOG_DATA) public data: DialogData ) { }
}