<div class="container-login-general">
    <img src="assets/img/X-CornerPattern-01.png" class="img-login-top">
    <img src="assets/img/X-CornerPattern-03.png" class="img-login-bottom">

    <!--app-intro
        *ngIf="show"
        [videoUrl]="'/assets/videos/GroupMNexus_Identifier_Animation_9sec.mp4'"
        (videoEnded)="onVideoEnded()">
    </app-intro-->

    <div class="container-login-general-info-content">
        <div class="logo-login">
            <img src="assets/img/logo_GMNCS.svg">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2 col-lg-4 offset-md-4 container-login">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="text-center">Welcome</h1>
                            <p class="mt-3 mb-3">Enter your information to continue please.</p>
                            <form class="w-100"
                                [formGroup]="formularioAdicionar"
                                autocomplete="off">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="fill">
                                        <mat-label>User</mat-label>
                                        <input matInput
                                                formControlName="usuario"
                                                placeholder="Ingrese el usuario"
                                                [value]="">
                                        <mat-icon matPrefix class="me-2">contact_mail</mat-icon>
                                        <span class="form-text text-danger"
                                                *ngIf="validateField('usuario')">
                                                User is required
                                        </span>
                                    </mat-form-field>
                                </div>
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="fill">
                                        <mat-label>Password</mat-label>
                                        <input matInput
                                                type="password"
                                                formControlName="clave"
                                                placeholder="Ingrese su contraseña"
                                                value="">
                                        <mat-icon matPrefix class="me-2">email</mat-icon>
                                        <span class="form-text text-danger"
                                                *ngIf="validateField('clave')">
                                                Password is required
                                        </span>
                                    </mat-form-field>
                                </div>
                                <div class="text-center mb-4">
                                    <button type="submit"
                                            (click)="login()"
                                            class="btn btn-primary">Ingresar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</div>
