<app-menu [login]="login" [nombres]="user != null ? user.names : ''"
    [perfil]="user != null ? user.profile_name : ''"></app-menu>

<div class="container container-info-home">
    <app-loading *ngIf="loading"></app-loading>
    <div class="row">
      <div class="col-md-12 text-center mt-4 mb-4 text-uppercase">
        <h1>CAMPAIGNS - ({{advertiserName}})</h1>
      </div>
      <div class="col-md-12 mb-2">
        <button class="btn btn-secondary"
          (click)="add(0)">
          Add
        </button>
        <button class="btn btn-secondary"
          (click)="return()">
          Return
        </button>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 card p-4">
            <mat-form-field appearance="fill" class="w-100">
            <mat-label>Type the campaign name</mat-label>
            <input type="text"
              matInput
              placeholder="Type"
              [(ngModel)]="sentenceSearch"
              [matAutocomplete]="auto"
              (input)="search()" />
            <mat-autocomplete autoActiveFirstOption
              #auto="matAutocomplete"
              (optionSelected)="selectedOption( $event )">
            <mat-option *ngFor="let campaign of campaingsFilters" [value]="campaign">
              <span>{{ campaign.name | titlecase }}</span> |
              <small> Status: {{ campaign.status | status }}</small>
            </mat-option>
            </mat-autocomplete>
            </mat-form-field>
          </div>
      </div>

      <div class="row">
        <div class="col-md-12 card p-4">
          <table mat-table [dataSource]="dataSource" class="w-100">

            <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name</th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="date_created">
            <th mat-header-cell *matHeaderCellDef> Created date</th>
            <td mat-cell *matCellDef="let element"> {{element.date_created | date: 'dd/MM/yyyy, h:mm a'}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">
              <div [className]="element.status === 'A' ? 'rounded-div-green' : 'rounded-div-red'">
                {{element.status | status }}
              </div>
            </td>
            </ng-container>

            <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
            <button mat-raised-button
              class="ms-2"
              (click)="add(element.id)"
              *ngIf="element.status === 'A'"
              color="primary">
              Edit
            </button>
            <button mat-raised-button
              class="ms-2"
              (click)="inactive(element.id)"
              color="primary">
              {{element.status === "I" ? "Activate" : "Inactivate" }}
            </button>
            <button mat-raised-button
              class="ms-2"
              *ngIf="element.status === 'A'"
              (click)="go(element.id)"
              color="primary">
              Preview
            </button>
            <button mat-raised-button
              class="ms-2"
              *ngIf="element.status === 'A'"
              (click)="copyToClipboard(element.id)"
              color="primary">
              Share preview
            </button>
            </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 card p-4">
          <div fxLayout="row" class="text-center {{ loading === true ? 'd-none' : '' }}">
          <mat-paginator #paginator
              [length]="total"
              pageSize="10"
              (page)="obtenerDataPagina($event)"
              [pageSizeOptions]="[10, 20, 30]"
              showFirstLastButtons="true"
              aria-label="Select page">
          </mat-paginator>
          </div>
        </div>
      </div>
    </div>
    </div>
</div>

<div class="container-footer">
  <app-footer></app-footer>
</div>






