import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as he from 'he';

import { CampaignFormat, ResponseCampaignFormats } from 'src/app/shared/interfaces/response-campaign-formats.interface';
import { DialogConfirmComponent } from 'src/app/shared/components/dialog-confirm/dialog-confirm.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CampaignDeta, ResponseCampaignDeta } from 'src/app/shared/interfaces/response-campaign-deta.interface';
import { Filter, Filters, ResponseCampaignFilter } from 'src/app/shared/interfaces/response-campaign-filters.interface';
import { FormatComments, ResponseCampaignFormatsComments, ResponseFormatsComments } from 'src/app/shared/interfaces/response-campaign-formats-comments.interface';
import { Campaign, ResponseCampaign } from 'src/app/shared/interfaces/response-campaign.interface';
import { ResponseCampaignData } from 'src/app/shared/interfaces/response-campaign-data.interface';
import { User } from 'src/app/auth/interfaces/response-login.interface';

import { AuthService } from 'src/app/auth/services/auth.service';
import { CampaignService } from '../../../admon/services/campaign.service';
import { DataService } from 'src/app/admon/services/data.service';
import { UtilidadesService } from 'src/app/shared/services/utilidades.service';

import { environment } from 'src/environments/environment';

import { APPROVED, HORIZONTAL_POSITION, PENDING, VERTICAL_POSITION } from 'src/app/shared/constants';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {
  currentYear = new Date().getFullYear();
  formularioAdicionar: UntypedFormGroup = this.fb.group({
    checked: [ true , [ ]   ],
    comments: [ false , [ ]   ],
    pending: [ false , [ ]   ],
  });
  loading: boolean = true;
  login: boolean = true;
  csvData: any[] = [];
  withChecked: boolean = false;
  withPending: boolean = false;
  checked: boolean = false;
  campaign: Campaign = null!;
  filters: Filters[] = [];
  user: User = this.authService.user;
  id: string = '';
  filter: string = '';
  show: boolean = true;
  campaignData: ResponseCampaignData = {
    result: []
  };
  campaignFormats: CampaignFormat[] = [];
  campaignFormatsFiltered: CampaignFormat[] = [];
  pathStorage: string = environment.baseApiVariations + '/api/file';
  approved: string = APPROVED;
  pending: string = PENDING;
  baseUrl: string = environment.baseUrl;
  capturedImage: string | null = null;
  showCampaign: boolean = true;
  existFilterSize: boolean = false;
  filterSize: Filter | undefined = undefined;

  constructor(private dialog: MatDialog,
              private clipboard: Clipboard,
              private snackBar: MatSnackBar,
              private fb: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private sanitizer: DomSanitizer,
              private cdr: ChangeDetectorRef,
              private authService: AuthService,
              private campaignService: CampaignService,
              private dataService: DataService,
              private utilidadesService: UtilidadesService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = this.utilidadesService.desencriptar(params["id"]);
      this.campaignService.getCampaignByID(Number(this.id)).subscribe({
        next: (response: ResponseCampaign) => {
          this.campaign = response.result[0];

          if(this.campaign.status === "I"){
            this.utilidadesService.mostrarModal('Error', `Campaign was inactivated`);
            this.showCampaign = false;
            this.return;
          }

          this.getFilters();
          this.getFormats();
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Error load data.`);
        }
      });

    });

    this.cdr.detectChanges();
  }

  getFilters(): void {
    this.campaignService.getCampaignFilters(this.id).subscribe({
      next: (response: ResponseCampaignFilter) => {
        this.filters = response.result;
        this.filters.forEach(filter => {
          console.log("filter.filter", filter.filter);
          if (filter.filter.filter_size === 0) {
            this.formularioAdicionar.addControl(filter.filter.name, new UntypedFormControl())
          } else {
            this.existFilterSize = true;
            this.filterSize = filter.filter;
          }
        });
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load filters.`);
      }
    });
  }

  getFormats(): void {
    this.campaignService.getCampaignFormats(this.id).subscribe({
      next: (response: ResponseCampaignFormats) => {
        this.campaignFormats = response.result;
        this.campaignFormats.forEach(format => {
          this.formularioAdicionar.addControl('format_' + format.id.toString(), new UntypedFormControl(true));
        });
        this.loading = false;
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load formats to show.`);
      }
    });
  }

  filteredFormats() {
    return this.campaignFormatsFiltered.filter(format => {
      const hasCommentsWithChecked = format.hasComment && this.withChecked;
      const hasCommentsWithPending = format.hasComment && this.withPending && format.hasPending;

      if (this.withChecked && this.withPending) {
        return hasCommentsWithPending;
      }

      if (this.withChecked) {
        return hasCommentsWithChecked;
      }

      if (this.withPending) {
        return hasCommentsWithPending;
      }

      return true;
    });
  }

  getData(): void {

    this.loading = true;
    let valid : boolean = false;
    this.filter = "";

    this.filters.forEach(filter => {
      const value = this.formularioAdicionar.get(filter.filter.name)?.value

      if(value != null && value != ""){
        this.filter = this.filter + ` c.field_${filter.filter.order}='${value}' AND`;
        valid = true;
      }
    });

    if (!valid && this.filters.length > 1) {
      this.utilidadesService.mostrarModal('Error', `You should select a filter`);
      this.loading = false;
      return;
    }

    this.filter = this.filter.substring(0, this.filter.length - 3).trim();

    if((this.filters.length === 0 || this.filters.length === 1) && this.filter === ""){
      this.filter = "1=1";
    }

    console.log(this.filter);

    this.campaignFormatsFiltered = [];

    this.campaignService.getCampaignData(this.id, this.filter).subscribe({
      next: (response: ResponseCampaignData) => {
        this.campaignData = response;

        if (this.campaignData.result.length === 0){
          this.utilidadesService.mostrarModal('Info', `Not found data by filters selected.`);
          this.loading = false;
          return;
        }

        const campaignData = response;

        this.campaignFormats.forEach(format => {
          const field = this.formularioAdicionar.get('format_' + format.id.toString());

          if (field?.value){
            this.campaignService.getCampaignDeta(format.id).subscribe({
              next: (responseDeta: ResponseCampaignDeta) => {
                let fields = responseDeta.result;
                console.log("fields by format", fields);
                this.createHtml(format, fields, campaignData);
            },
            error: () => {
              this.utilidadesService.mostrarModal('Error', `Error load data format.`);
            }});
          }
        });

        this.loading = false;
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load data.`);
      }
    });
  }

  createHtml(format: CampaignFormat, fields: CampaignDeta[], campaignData: ResponseCampaignData): void {

      this.campaignService.getCampaignFormatsCommentsByIDs(format.id).subscribe({
        next: (responseComments: ResponseCampaignFormatsComments) => {

          let path = this.pathStorage + "?path=" + this.campaign.path + "&path_format=" + format.path + "&name=";
          this.dataService.getDataOrigin(path + "index.html").subscribe({
            next: (dataHtml: any) => {

              campaignData.result.forEach(data => {
                if(this.existFilterSize && data?.['field_' + this.filterSize?.order!]?.toString() !== format.name){
                  console.log("No coincide la data");
                  return;
                }
                console.log("Continua");

                let formatCreated = {...format};
                formatCreated.comments = responseComments.result;
                formatCreated.hasComment = false;
                formatCreated.comments.forEach(comment => {
                  if (comment.variation_id.toString() === (data?.['field_1']?.toString() || '')){
                    formatCreated.hasComment = true;
                    this.return;
                  }
                });
                formatCreated.hasPending = false;
                formatCreated.comments.forEach(comment => {
                  if (comment.variation_id.toString() === (data?.['field_1']?.toString() || '') && comment.status === 'P'){
                    formatCreated.hasPending = true;
                    this.return;
                  }
                });
                formatCreated.data = data;
                console.log("format",formatCreated);

                this.formularioAdicionar.addControl("comment_" + format.id + "_" + data[`field_1`], new UntypedFormControl());
                //Reemplazos de rutas
                let htmlContent = dataHtml;
                htmlContent = htmlContent.replace(/gwd-image source="/g, 'gwd-image source="' + path);
                htmlContent = htmlContent.replace(/mask-image:url\("/g, 'mask-image:url("' + path);
                htmlContent = htmlContent.replace(/src:url\("/g, 'src:url("' + path);
                htmlContent = htmlContent.replace(/src: url\('/g, 'src: url(\'' + path);
                htmlContent = htmlContent.replace(/,url\("/g, ',url("' + path);
                //htmlContent = htmlContent.replace(/gwd-image id="Icon" source="/g, 'gwd-image id="Icon" source="' + path);
                htmlContent = htmlContent.replace(/gwd-image( id="[^"]*")? source="([^"]*)"/g, (match: string, idAttr: string, sourceAttr: string) => {
                  return `gwd-image${idAttr || ''} source="${path}${sourceAttr}"`;
                });

                //Reemplaza los datos
                fields.forEach(filter => {
                  const field = data[`field_${filter.order}`];
                  console.log(filter.field_html, field);

                  const dynamicContentStart = htmlContent.indexOf('var devDynamicContent = {};');
                  const dynamicContentEnd = htmlContent.indexOf('Enabler.setDevDynamicContent(devDynamicContent);');
                  const dynamicContent = htmlContent.substring(dynamicContentStart, dynamicContentEnd).trim();

                  let dataColumns = dynamicContent.split(';');

                  dataColumns.slice(1).forEach((element: string) => {
                    const fieldSearch = this.dataService.getPath(element, []);

                    if(fieldSearch.trim().toLowerCase() === filter.field_html.trim().toLowerCase()){
                      console.log('Encontrado', field, filter.field_html);
                      const value = element.split(" = ");
                      const valueDecode = he.decode(field === null ? '' : field).replace(/"/gi, "'");

                      const newValue = ` = "${valueDecode}"`;
                      console.log("valor buscado", element);
                      console.log("reemplazo", `${value[0]}${newValue}`);

                      htmlContent = htmlContent.replace(element, `${value[0]}${newValue}`);
                      return;
                    }
                  });
                });

                htmlContent = this.addScriptToHead(htmlContent);

                //Crea el HTML
                let sanitizedHtml: SafeResourceUrl | undefined;
                const blob = new Blob([htmlContent], { type: 'text/html' });
                const url = URL.createObjectURL(blob);
                sanitizedHtml = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                formatCreated.safeHtml = sanitizedHtml;
                formatCreated.html = htmlContent;
                this.campaignFormatsFiltered.push(formatCreated);
              });

          },
          error: (msg: any) => {
            console.log("Error convert html", msg);
            this.utilidadesService.mostrarModal('Error', `Error load html to convert.`);
          }});
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load data format.`);
      }});
  }

  addComment(idFormat: number, id: string, status: string, aproved: boolean = false): void {

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {titulo: 'Confirm', texto: 'Are you certain about this action?'},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const textAproved: string = `Approved by user ${ this.user.names }.`;
        const request: FormatComments =  {
          id:                 Number(id),
          campaign_format_id: idFormat,
          variation_id:       Number(id),
          user_id:            this.user.id,
          user:               this.user.names,
          comments:           aproved ? textAproved : this.formularioAdicionar.get('comment_' + idFormat + '_' + id)?.value,
          status:             status,
          created_date:       new Date()
        };

        this.addNewComment(request, idFormat, id);
      }
    });
  }

  approvedAll(): void {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {titulo: 'Confirm', texto: 'Are you certain about this action?'},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const textAproved: string = `Approved by user ${ this.user.names }.`;

        this.campaignFormatsFiltered.forEach( format => {
          const request: FormatComments =  {
            id:                 Number((format.data?.['field_1']?.toString() || '')),
            campaign_format_id: format.id,
            variation_id:       Number((format.data?.['field_1']?.toString() || '')),
            user_id:            this.user.id,
            user:               this.user.names,
            comments:           textAproved,
            status:             APPROVED,
            created_date:       new Date()
          };

          this.addNewComment(request, format.id, (format.data?.['field_1']?.toString() || ''), false);
        });


        this.utilidadesService.mostrarModal('Info', `Formats approved succesfully.`);
      }
    });
  }

  addNewComment(request: FormatComments, idFormat: number, id: string, showModal: boolean = true): void {
    this.campaignService.addCampaignFormatsComments(request).subscribe({
      next: (response: ResponseFormatsComments) => {
        if (response.error === ""){
          this.campaignService.getCampaignFormatsCommentsByIDs(idFormat).subscribe({
            next: (responseComments: ResponseCampaignFormatsComments) => {
                this.campaignFormatsFiltered.forEach( format => {
                  if (format.id === idFormat){
                    format.comments = responseComments.result;
                  }
                });
                this.formularioAdicionar.get('comment_' + idFormat + '_' + id)?.setValue('');
                if (showModal) {
                  this.utilidadesService.mostrarModal('Info', `Comment add succesfully.`);
                }
            },
            error: () => {
              this.utilidadesService.mostrarModal('Error', `Error load comments.`);
            }
          });
        }
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load filters.`);
      }
    });
  }

  updateStatus(id: number, idFormat: number, status: string): void {

    if (this.user.profile_id === 2) {
      return;
    }

    const request: FormatComments =  {
      id:                 id,
      campaign_format_id: 0,
      variation_id:       0,
      user_id:            this.user.id,
      user:               this.user.names,
      comments:           '',
      status:             status,
      created_date:       new Date()
    };

    this.campaignService.updateCampaignFormatsComments(request).subscribe({
      next: (response: ResponseFormatsComments) => {
        if (response.error === ""){
          this.campaignService.getCampaignFormatsCommentsByIDs(idFormat).subscribe({
            next: (responseComments: ResponseCampaignFormatsComments) => {
                this.campaignFormatsFiltered.forEach( format => {
                  if (format.id === idFormat){
                    format.comments = responseComments.result;
                  }
                });
                this.utilidadesService.mostrarModal('Info', `Status updated succesfully.`);
            },
            error: () => {
              this.utilidadesService.mostrarModal('Error', `Error load comments.`);
            }
          });
        }
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load filters.`);
      }
    });
  }

  getUrl(id: number, idData: string): string {
    return `/preview-html?id=${this.utilidadesService.encriptar(id.toString())}&idData=${idData}`;
  }

  selectAll(): void {
    const allChecked = this.formularioAdicionar.get('checked')?.value;

    this.campaignFormats.forEach(format => {
      this.formularioAdicionar.get('format_' + format.id)?.setValue(allChecked, { emitEvent: false });
    });
  }

  withComments(): void {
    const checked = this.formularioAdicionar.get('comments')?.value;
    this.withChecked = checked;
    this.getData();
  }

  getPending(): void {
    const checked = this.formularioAdicionar.get('pending')?.value;
    this.withPending = checked;
    this.getData();
  }

  validate(event: MatCheckboxChange, formatId: number): void {
    const isChecked = event.checked;
    this.formularioAdicionar.get('format_' + formatId)?.setValue(isChecked, { emitEvent: false });

    const allChecked = this.campaignFormats.every(format =>
      this.formularioAdicionar.get('format_' + format.id)?.value === true
    );

    const currentAllChecked = this.formularioAdicionar.get('checked')?.value;
    if (allChecked !== currentAllChecked) {
      this.formularioAdicionar.get('checked')?.setValue(allChecked, { emitEvent: false });
    }
  }

  refreshIframe(id: string) {
    const iframe = document.getElementById(`iframe-${id}`) as HTMLIFrameElement;
    if (iframe) {
      iframe.src = iframe.src;
    }
  }

  createIframe(id: string, html: string) {
    const iframe = document.getElementById(`iframe2-${id}`) as HTMLIFrameElement;
    console.log("iframe", iframe);

    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
    if (iframeDoc) {
      iframeDoc.open();
      iframeDoc.write(html);
      iframeDoc.close();
    }
  }

  captureIframe(id: string) {
    const iframe = document.getElementById(`iframe2-${id}`) as HTMLIFrameElement;

    if (!iframe) {
      console.error('No se encontró el iframe');
      return;
    }

    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;

    if (iframeDoc) {
      // Si el iframe ya está cargado (readyState completo o acceso al body)
      if (iframeDoc.readyState === 'complete' || iframeDoc.body) {
        this.captureIframeContent(iframe);
      } else {
        // Si el iframe aún no está cargado, usamos el onload
        iframe.onload = () => {
          this.captureIframeContent(iframe);
        };
      }
    } else {
      console.error('No se pudo acceder al documento del iframe');
    }
  }

  captureIframeContent(iframe: HTMLIFrameElement) {
    html2canvas(iframe).then(canvas => {
      const imgDataUrl = canvas.toDataURL('image/png');
      this.capturedImage = imgDataUrl;
      console.log('Captura generada:', imgDataUrl);
    }).catch(error => {
      console.error('Error capturando el contenido:', error);
    });
  }

  return(): void{
    this.router.navigate(['/', 'campaigns'], {
      queryParams: {
        id: this.utilidadesService.encriptar(this.id)
      }
    });
  }

  copyToClipboard(id: string): void {
    const link: string = this.baseUrl + 'preview?id=' + this.utilidadesService.encriptar(id);
    this.clipboard.copy(link);
    this.snackBar.open('¡URL copied to clipboard!','Got it', {
      duration: 2500,
      panelClass: 'snackbar',
      horizontalPosition: HORIZONTAL_POSITION,
      verticalPosition: VERTICAL_POSITION,
    });
  }

  addScriptToHead(htmlContent: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const script = doc.createElement('script');
    script.setAttribute('data-source', 'https://s0.2mdn.net/ads/studio/Enabler.js');
    script.setAttribute('data-exports-type', 'gwd-google-ad');
    script.src = 'https://s0.2mdn.net/ads/studio/Enabler.js';
    doc.head.appendChild(script);

    return doc.documentElement.outerHTML;
  }

  onVideoEnded(): void {
    this.show = false;
  }
}
