import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { ResponseFormats } from 'src/app/shared/interfaces/response-formats.interface';

import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdmonService {

  private _baseApi = environment.baseApiVariations;

  constructor(private http: HttpClient,
              private authService: AuthService) { }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.authService.token
      })
  };

  getFormats(): Observable<ResponseFormats>{
    let headers = this.getHeaders();
    return this.http.get<ResponseFormats>(`${ this._baseApi }/api/v1/formats`, { headers });
  }
}
