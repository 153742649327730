import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Region, ResponseRegion, ResponseRegionCreated } from 'src/app/shared/interfaces/response-region.interface';
import { ResponseUser, UserData } from 'src/app/shared/interfaces/response-user.interface';
import { User } from 'src/app/auth/interfaces/response-login.interface';

import { AuthService } from 'src/app/auth/services/auth.service';
import { RegionService } from 'src/app/admon/services/region.service';
import { UtilidadesService } from 'src/app/shared/services/utilidades.service';
import { UserService } from 'src/app/admon/services/user.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  loading: boolean = true;
  user: User = this.authService.user;
  login: boolean = true;
  id: number = 0;
  userData: UserData = null!;
  regions: Region[] = [];
  regionFormGroup = this.fb.group({
    names: ['', Validators.required],
    identification: ['', Validators.required],
    email: ['', Validators.required],
    pass: [''],
    profile_id: [0, Validators.required],
    region_id: [0, Validators.required],
  });

  constructor(private authService: AuthService,
    private regionService: RegionService,
    private userService: UserService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private utilidadesService: UtilidadesService) { }

    ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
        this.authService.validarLogin();
        if(this.user.profile_id === 2) {
          this.utilidadesService.mostrarModal('Error', `Forbidden view to profile selected`);
          this.utilidadesService.goHome();
          this.return;
        }

        this.id = Number(this.utilidadesService.desencriptar(params["id"]));

        this.regionService.getRegion(0, 1000).subscribe({
          next: (response: ResponseRegion) => {
            this.regions = response.result;

            if(this.user.profile_id === 3) {
              this.regions = this.regions.filter(reg => reg.id === this.user.region_id);
            }
          },
          error: () => {
            this.utilidadesService.mostrarModal('Error', `Error load data.`);
          }
        });

        if(this.id > 0) {
          this.getData();
        } else {
          this.loading = false;
        }
      });
    }

    getData(): void {
      this.userService.getUserByID(Number(this.id)).subscribe({
        next: (response: ResponseUser) => {
          this.userData = response.result[0];
          this.regionFormGroup.setControl('names', new FormControl(this.userData.names));
          this.regionFormGroup.setControl('identification', new FormControl(this.userData.identification));
          this.regionFormGroup.setControl('email', new FormControl(this.userData.email));
          this.regionFormGroup.setControl('pass', new FormControl(''));
          this.regionFormGroup.setControl('profile_id', new FormControl(this.userData.profile_id!));
          this.regionFormGroup.setControl('region_id', new FormControl(this.userData.region_id!));
          this.loading = false;
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Error load data.`);
        }
      });
    }

    save(): void {
      if ( this.regionFormGroup.invalid )  {
        this.regionFormGroup.markAllAsTouched();
        this.utilidadesService.mostrarModal('Error', `All fiels are required.`);
        return;
      }

      this.loading = true;
      const identification: string = this.regionFormGroup.get('identification')?.value!;
      const names: string = this.regionFormGroup.get('names')?.value!;
      const email: string = this.regionFormGroup.get('email')?.value!;
      const pass: string = this.regionFormGroup.get('pass')?.value!;
      const profile_id: number = Number(this.regionFormGroup.get('profile_id')?.value!);
      const region_id: number = Number(this.regionFormGroup.get('region_id')?.value!);
      const request: UserData = {
        id: this.id,
        identification: identification,
        names:          names,
        email:          email,
        status:         'A',
        created_date:   new Date(),
        profile_name:   '',
        region_name:    '',
        pass:           pass,
        profile_id:    profile_id,
        region_id:     region_id,
      };

      this.userService.addUser(request).subscribe({
        next: (response: ResponseRegionCreated) => {
          this.id = response.result;
          this.getData();
          this.utilidadesService.mostrarModal('Info', `Save data succesfully.`);
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Error load data.`);
        }
      });
    }

    return(): void{
      this.router.navigate(['/', 'users']);
    }
}
