import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DialogResultComponent } from './components/dialog-result/dialog-result.component';
import { DialogShowComponent } from './components/dialog-show/dialog-show.component';
import { LoadingComponent } from './components/loading/loading.component';

import { MaterialModule } from '../material/material.module';

import { SafePipe } from './pipes/safe.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { DialogConfirmComponent } from './components/dialog-confirm/dialog-confirm.component';
import { IntroComponent } from './components/intro/intro.component';

@NgModule({
  declarations: [
    DialogConfirmComponent,
    DialogShowComponent,
    DialogResultComponent,
    IntroComponent,
    LoadingComponent,
    SafePipe,
    StatusPipe,
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    DialogConfirmComponent,
    DialogShowComponent,
    DialogResultComponent,
    IntroComponent,
    LoadingComponent,
    SafePipe,
    StatusPipe,
  ],
})
export class SharedModule { }
