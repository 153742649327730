import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';

import { Region, RequestRegion, ResponseRegion } from 'src/app/shared/interfaces/response-region.interface';
import { User } from 'src/app/auth/interfaces/response-login.interface';

import { AuthService } from 'src/app/auth/services/auth.service';
import { UserService } from '../../services/user.service';
import { UtilidadesService } from 'src/app/shared/services/utilidades.service';

import { environment } from 'src/environments/environment';
import { ResponseUser, UserData } from 'src/app/shared/interfaces/response-user.interface';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent {
  loading: boolean = true;
  user: User = this.authService.user;
  login: boolean = true;
  total: number = 0;
  totalOriginal: number = 0;
  sentenceSearch: string  = '';
  users: UserData[] = [];
  usersOriginal: UserData[] = [];
  usersFilters:UserData[] = [];
  dataSource = new MatTableDataSource(this.users);
  displayedColumns: string[] = ['identification', 'name', 'email', 'status', 'profile', 'created_date', 'actions'];
  baseUrl: string = environment.baseUrl;

  constructor(private authService: AuthService,
              private userService: UserService,
              private router: Router,
              private utilidadesService: UtilidadesService) { }

  ngOnInit(): void {
    this.authService.validarLogin();
    if(this.user.profile_id === 2) {
      this.utilidadesService.mostrarModal('Error', `Forbidden view to profile selected`);
      this.utilidadesService.goHome();
      this.return;
    }

    this.getData(0, 10);
  }

  obtenerDataPagina(event: PageEvent): PageEvent {
    this.getData(event.pageIndex, event.pageSize);
    return event;
  }

  getData(offset: number, limit: number): void {
    const idRegion = this.user.profile_id === 1 ? 0 : this.user.region_id;
    this.userService.getUser(idRegion, offset, limit).subscribe({
      next: (response: ResponseUser) => {
        this.users = response.result;
        this.usersOriginal = response.result;
        this.total = response.total;
        this.totalOriginal = response.total;
        this.dataSource = new MatTableDataSource(this.users);
        this.loading = false;
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load data.`);
      }
    });
  }

  search(){
    this.usersFilters = [];

    if(this.sentenceSearch.length === 0) {
      this.total = this.totalOriginal;
      this.dataSource = new MatTableDataSource(this.usersOriginal);
      this.return;
    }

    if(this.sentenceSearch.trim().length > 3){
      this.loading = true;
      const idRegion = this.user.profile_id === 1 ? 0 : this.user.region_id;
      this.userService.getUserFilter(idRegion, this.sentenceSearch.toLowerCase().trim()).subscribe({
        next: (response: ResponseUser) => {
          this.users = response.result;
          this.total = response.total;
          this.dataSource = new MatTableDataSource(this.users);
          this.loading = false;
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Not found.`);
        }});
    }
  }

  selectedOption( event: MatAutocompleteSelectedEvent) {
    if(!event.option.value)
      return;

    const data: UserData = event.option.value;
  }

  add(id: number): void {
    this.router.navigate(['/', 'users-create'], {
      queryParams: {
        id: this.utilidadesService.encriptar(id.toString())
      }
    });
  }

  inactive(id: number): void {
    this.loading = true;
    const request: UserData = {
      id: id,
      identification: '',
      names:          '',
      email:          '',
      status:         '',
      created_date:   new Date(),
      profile_name:   '',
      region_name:    '',
    };

    this.userService.inactiveUser(request).subscribe({
      next: () => {
        this.getData(0, 10);
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error save data.`);
      }
    });
  }

  return(): void{
    this.utilidadesService.goHome();
  }
}
