import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { RequestAdvertiser, RequestAdvertiserUser, ResponseAdvertiser, ResponseAdvertiserCreated, ResponseAdvertiserUser } from 'src/app/shared/interfaces/response-advertiser.interface';

import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdvertiserService {

  private _baseApi = environment.baseApiVariations;

  constructor(private http: HttpClient,
              private authService: AuthService) { }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.authService.token
      })
  };

  getAdvertiser(regionId: number, offset: number, limit: number): Observable<ResponseAdvertiser>{
    let headers = this.getHeaders();
    return this.http.get<ResponseAdvertiser>(`${ this._baseApi }/api/v1/advertiser?region_id=${regionId}&offset=${offset}&limit=${limit}`, { headers });
  }

  getAdvertiserByID(id: number): Observable<ResponseAdvertiser>{
    let headers = this.getHeaders();
    return this.http.get<ResponseAdvertiser>(`${ this._baseApi }/api/v1/advertiser-id?id=${id}`, { headers });
  }

  getAdvertiserFilter(regionId: number, name: string): Observable<ResponseAdvertiser>{
    let headers = this.getHeaders();
    return this.http.get<ResponseAdvertiser>(`${ this._baseApi }/api/v1/advertiser-filter?region_id=${regionId}&name=${name}`, { headers });
  }

  getAdvertiserByUser(advertiserId: number, offset: number, limit: number): Observable<ResponseAdvertiserUser>{
    let headers = this.getHeaders();
    return this.http.get<ResponseAdvertiserUser>(`${ this._baseApi }/api/v1/advertiser-user?advertiser_id=${advertiserId}&offset=${offset}&limit=${limit}`, { headers });
  }

  getAdvertiserByUserFilter(name: string): Observable<ResponseAdvertiserUser>{
    let headers = this.getHeaders();
    return this.http.get<ResponseAdvertiserUser>(`${ this._baseApi }/api/v1/advertiser-user-filter?name=${name}`, { headers });
  }

  addAdvertiser(request: RequestAdvertiser): Observable<ResponseAdvertiserCreated>{
    let headers = this.getHeaders();
    return this.http.post<ResponseAdvertiserCreated>(`${ this._baseApi }/api/v1/advertiser`, request, { headers });
  }

  addAdvertiserUser(request: RequestAdvertiserUser): Observable<ResponseAdvertiserCreated>{
    let headers = this.getHeaders();
    return this.http.post<ResponseAdvertiserCreated>(`${ this._baseApi }/api/v1/advertiser-user`, request, { headers });
  }

  inactiveAdvertiser(request: RequestAdvertiser): Observable<ResponseAdvertiserCreated>{
    let headers = this.getHeaders();
    return this.http.put<ResponseAdvertiserCreated>(`${ this._baseApi }/api/v1/advertiser/inactivate`, request, { headers });
  }
}
