import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  /**
   * Transform
   *
   * @param value: string
   */
  transform(value: string): string {
    switch (value) {
      case 'A':
        return 'Active';
      case 'I':
        return 'Inactive';
      case 'P':
        return 'Pending';
      case 'F':
          return 'Finished';
      default:
        return 'Inactive';
    }
  }

}
