import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { ResponseRegionCreated } from 'src/app/shared/interfaces/response-region.interface';
import { ResponseUser, UserData } from 'src/app/shared/interfaces/response-user.interface';

import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _baseApi = environment.baseApiVariations;

  constructor(private http: HttpClient,
              private authService: AuthService) { }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.authService.token
      })
  };

  getUser(regionId: number, offset: number, limit: number): Observable<ResponseUser>{
    let headers = this.getHeaders();
    return this.http.get<ResponseUser>(`${ this._baseApi }/api/v1/user?region_id=${regionId}&offset=${offset}&limit=${limit}`, { headers });
  }

  getUserByID(id: number): Observable<ResponseUser>{
    let headers = this.getHeaders();
    return this.http.get<ResponseUser>(`${ this._baseApi }/api/v1/user-id?id=${id}`, { headers });
  }

  getUserFilter(regionId: number, name: string): Observable<ResponseUser>{
    let headers = this.getHeaders();
    return this.http.get<ResponseUser>(`${ this._baseApi }/api/v1/user-filter?region_id=${regionId}&name=${name}`, { headers });
  }

  addUser(request: UserData): Observable<ResponseRegionCreated>{
    let headers = this.getHeaders();
    return this.http.post<ResponseRegionCreated>(`${ this._baseApi }/api/v1/user`, request, { headers });
  }

  inactiveUser(request: UserData): Observable<ResponseRegionCreated>{
    let headers = this.getHeaders();
    return this.http.put<ResponseRegionCreated>(`${ this._baseApi }/api/v1/user/inactivate`, request, { headers });
  }
}
