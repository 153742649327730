<app-menu [login]="login" [nombres]="user != null ? user.names : ''"
    [perfil]="user != null ? user.profile_name : ''"></app-menu>

<div class="container container-info-home">
    <div class="row">
        <div class="col-md-12 text-center mt-4 mb-4">
            <h1>WELCOME</h1>
        </div>
        <div class="col-md-3" *ngIf="user.profile_id === 1">
          <div class="container-modulo-home text-center" [style]="active1 ? 'cursor: pointer' : ''"  (click)="active1 ? irPagina('region', '1') : ''">
              <h3>ACCOUNTS</h3>
              <img [src]="active1 ? '/assets/img/True_Teal_Icons/10_True Teal.png' : '/assets/img/True_Teal_Icons/10_True Teal.png'" class="img-modulo-home"/>
          </div>
        </div>
        <div class="col-md-3" *ngIf="user.profile_id === 3">
          <div class="container-modulo-home text-center" [style]="active1 ? 'cursor: pointer' : ''"  (click)="active1 ? irPagina('advertiser', '1') : ''">
              <h3>ADVERTISERS</h3>
              <img [src]="active1 ? '/assets/img/True_Teal_Icons/10_True Teal.png' : '/assets/img/True_Teal_Icons/10_True Teal.png'" class="img-modulo-home"/>
          </div>
      </div>
        <div class="col-md-3" *ngIf="user.profile_id === 2">
            <div class="container-modulo-home text-center" [style]="active1 ? 'cursor: pointer' : ''" (click)="active1 ? irPagina('campaigns-user', '1') : ''">
                <h3>CAMPAIGNS</h3>
                <img [src]="active1 ? '/assets/img/True_Teal_Icons/10_True Teal.png' : '/assets/img/True_Teal_Icons/10_True Teal.png'" class="img-modulo-home"/>
            </div>
        </div>
        <div class="col-md-3" *ngIf="user.profile_id === 1 || user.profile_id === 3">
            <div class="container-modulo-home text-center" [style]="active1 ? 'cursor: pointer' : ''" (click)="active1 ? irPagina('users', '1') : ''">
                <h3>USERS</h3>
                <img [src]="active1 ? '/assets/img/True_Teal_Icons/07_True Teal.png' : '/assets/img/True_Teal_Icons/10_True Teal.png'" class="img-modulo-home"/>
            </div>
        </div>
    </div>
</div>

<div class="container-footer">
    <app-footer></app-footer>
</div>

