import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { User } from 'src/app/auth/interfaces/response-login.interface';

import { AuthService } from 'src/app/auth/services/auth.service';
import { UtilidadesService } from 'src/app/shared/services/utilidades.service';
import { RegionService } from '../../../services/region.service';
import { Region, RequestRegion, ResponseRegion, ResponseRegionCreated } from 'src/app/shared/interfaces/response-region.interface';

@Component({
  selector: 'app-create-region',
  templateUrl: './create-region.component.html',
  styleUrls: ['./create-region.component.css']
})
export class CreateRegionComponent implements OnInit {
  loading: boolean = true;
  user: User = this.authService.user;
  login: boolean = true;
  id: number = 0;
  region: Region = null!;
  regionFormGroup = this.fb.group({
    name: ['', Validators.required],
  });

  constructor(private authService: AuthService,
    private regionService: RegionService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private utilidadesService: UtilidadesService) { }

    ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
        this.authService.validarLogin();
        if(this.user.profile_id > 1) {
          this.utilidadesService.mostrarModal('Error', `Forbidden view to profile selected`);
          this.utilidadesService.goHome();
          this.return;
        }

        this.id = Number(this.utilidadesService.desencriptar(params["id"]));

        if(this.id > 0) {
          this.getData();
        } else {
          this.loading = false;
        }
      });
    }

    getData(): void {
      this.regionService.getRegionByID(Number(this.id)).subscribe({
        next: (response: ResponseRegion) => {
          this.region = response.result[0];
          this.regionFormGroup.setControl('name', new FormControl(this.region.name));
          this.loading = false;
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Error load data.`);
        }
      });
    }

    save(): void {
      if ( this.regionFormGroup.invalid )  {
        this.regionFormGroup.markAllAsTouched();
        this.utilidadesService.mostrarModal('Error', `Name is required.`);
        return;
      }

      this.loading = true;
      const name: string = this.regionFormGroup.get('name')?.value!
      const request: RequestRegion = {
        id: this.id,
        name: name,
      };

      this.regionService.addRegion(request).subscribe({
        next: (response: ResponseRegionCreated) => {
          this.id = response.result;
          this.getData();
          this.utilidadesService.mostrarModal('Info', `Save data succesfully.`);
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Error load data.`);
        }
      });
    }

    return(): void{
      this.router.navigate(['/', 'region']);
    }
}
