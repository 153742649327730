<app-menu [login]="login" [nombres]="user != null ? user.names : ''"
    [perfil]="user != null ? user.profile_name : ''"></app-menu>

<div class="container container-info-home">
    <app-loading *ngIf="loading"></app-loading>
    <div class="row">
      <div class="col-md-12 text-center mt-4 mb-4">
        <h1>USERS</h1>
      </div>
      <div class="col-md-12 mb-2 text-end">
        <button class="btn btn-secondary"
          (click)="add(0)">
          Add
        </button>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 card p-4">
            <mat-form-field appearance="fill" class="w-100">
            <mat-label>Type the campaign name</mat-label>
            <input type="text"
              matInput
              placeholder="Type"
              [(ngModel)]="sentenceSearch"
              [matAutocomplete]="auto"
              (input)="search()" />
            <mat-autocomplete autoActiveFirstOption
              #auto="matAutocomplete"
              (optionSelected)="selectedOption( $event )">
            <mat-option *ngFor="let user of usersFilters" [value]="user">
              <span>{{ user.names | titlecase }}</span> |
              <small> Status: {{ user.status | status }}</small>
            </mat-option>
            </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 card p-4">
            <table mat-table [dataSource]="dataSource" class="w-100">

              <ng-container matColumnDef="identification">
                <th mat-header-cell *matHeaderCellDef> Identification</th>
                <td mat-cell *matCellDef="let element">
                  {{element.identification}}
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name</th>
                <td mat-cell *matCellDef="let element">
                  {{element.names}}
                </td>
              </ng-container>

              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Email</th>
                <td mat-cell *matCellDef="let element">
                  {{element.email}}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element">
                <div [className]="element.status === 'A' ? 'rounded-div-green' : 'rounded-div-red'">
                  {{element.status | status }}
                </div>
              </td>
              </ng-container>

              <ng-container matColumnDef="profile">
                <th mat-header-cell *matHeaderCellDef> Profile</th>
                <td mat-cell *matCellDef="let element">
                  {{element.profile_name}}
                </td>
              </ng-container>

              <ng-container matColumnDef="created_date">
                <th mat-header-cell *matHeaderCellDef> Created date</th>
                <td mat-cell *matCellDef="let element">
                  {{element.created_date}}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> Actions </th>
              <td mat-cell *matCellDef="let element">
                <button mat-raised-button
                  class="ms-2"
                  (click)="add(element.id)"
                  *ngIf="element.status === 'A'"
                  color="primary">
                  Edit
                </button>
                <button mat-raised-button
                  class="ms-2"
                  (click)="inactive(element.id)"
                  color="primary">
                  {{element.status === "I" ? "Activate" : "Inactivate" }}
                </button>

              </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 card p-4">
            <div fxLayout="row" class="text-center {{ loading === true ? 'd-none' : '' }}">
            <mat-paginator #paginator
                [length]="total"
                pageSize="10"
                (page)="obtenerDataPagina($event)"
                [pageSizeOptions]="[10, 20, 30]"
                showFirstLastButtons="true"
                aria-label="Select page">
            </mat-paginator>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mb-2 text-center mt-4">
        <button class="btn btn-secondary"
          (click)="return()">
          Home
        </button>
      </div>
    </div>
</div>

<div class="container-footer">
  <app-footer></app-footer>
</div>






