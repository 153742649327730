<app-menu [login]="login" [nombres]="user != null ? user.names : ''"
    [perfil]="user != null ? user.profile_name : ''"></app-menu>

<div class="container container-info-home">
  <app-loading *ngIf="loading"></app-loading>
  <div class="row">
    <div class="col-md-12 text-center mt-4 mb-4 text-uppercase">
      <h1>CREATE CAMPAIGN</h1>
    </div>
    <div class="col-md-12 mb-2 p-3">
      <mat-stepper [linear]="isLinear" #stepper>
        <mat-step [stepControl]="campaignFormGroup" [editable]="isStep1Editable">
          <form [formGroup]="campaignFormGroup">
            <ng-template matStepLabel>Create campaing</ng-template>
            <mat-form-field appearance="fill">
              <mat-label>Name</mat-label>
              <input matInput
                      placeholder="Name"
                      formControlName="name"/>
            </mat-form-field>
            <div>
              <button mat-raised-button color="primary" (click)="goToFilters(stepper)">Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="filtersFormGroup" [editable]="isStep2Editable">
          <form [formGroup]="filtersFormGroup">
            <ng-template matStepLabel>Create filters</ng-template>
            <div *ngIf="campaign">Select filters to {{campaign.name}}</div>
            <div *ngIf="edit">
              <mat-checkbox
                  class="check-filters-preview"
                  formControlName="loadFile"
                  value="checkLoadFile"
                  (change)="checkLoadFile()">
                  Load new File
              </mat-checkbox>
            </div>
            <div *ngIf="loadFile">
              Data (.csv,.tsv,.xlsx,.xls)
              <mat-form-field appearance="fill">
                <mat-label>Load file data</mat-label>
                <input matInput
                      [value]="CsvName"
                      accept=".csv,.tsv,.xlsx,.xls"
                      (click)="fileInputCsv.click()"
                      readonly />
                <input type="file"
                        (change)="onFileChange($event)"
                        hidden
                        #fileInputCsv />
                <button mat-icon-button
                        matSuffix
                        (click)="fileInputCsv.click()">
                  <mat-icon>attach_file</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <br/><br/>
            <table>
                <tr *ngFor="let header of csvData[0]">
                    <td>
                        <input matInput placeholder="" [value]="header" [readonly]="true" #input>
                    </td>
                    <td>
                        <section>
                            <mat-checkbox [formControlName]="header + '_filter'">Select filter</mat-checkbox>
                        </section>
                    </td>
                    <td>
                        <section>
                            <mat-checkbox [formControlName]="header + '_filter_size'">Select filter size</mat-checkbox>
                        </section>
                    </td>
                </tr>
            </table>
            <div>
              <button mat-raised-button color="primary" (click)="goToFormats(stepper)">Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" [editable]="isStep3Editable">
          <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>Create formats</ng-template>
            <mat-accordion>
              <mat-expansion-panel *ngFor="let format of dataFormats; let i = index">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Load format {{ format.id > 0 ? format.name : i + 1 }}
                  </mat-panel-title>
                  <mat-panel-description>
                    {{ format.id > 0 ? 'Update format' : 'Create a new format to load ' + format.name !== '' ? 'Format ' + format.name: '' }}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <app-create-format [headers]="headers" [campaign]="campaign" [format]="format"></app-create-format>
              </mat-expansion-panel>
            </mat-accordion>
            <button mat-raised-button color="primary" (click)="addPanel()">Add new format</button>
            <div>
              <button mat-raised-button color="primary" (click)="goToFinish(stepper)">Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Done</ng-template>
          <p>The campaign is complete.</p>
          <div>
            <button mat-raised-button color="primary" (click)="preview(id)">Preview</button>
            <button mat-raised-button color="primary" (click)="return()">Finish</button>
          </div>
        </mat-step>
      </mat-stepper>
    </div>
    <div class="col-md-12 mb-2 text-center">
      <button mat-raised-button color="primary" (click)="return()">Return</button>
    </div>
  </div>
</div>
<div class="container-footer">
  <app-footer></app-footer>
</div>




