import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { ResponseCampaign } from 'src/app/shared/interfaces/response-campaign.interface';

import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignUserService {

  private _baseApi = environment.baseApiVariations;

  constructor(private http: HttpClient,
              private authService: AuthService) { }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.authService.token
      })
  };

  getCampaignsUser(userId: number, offset: number, limit: number): Observable<ResponseCampaign>{
    let headers = this.getHeaders();
    return this.http.get<ResponseCampaign>(`${ this._baseApi }/api/v1/campaigns-user?user_id=${userId}&offset=${offset}&limit=${limit}`, { headers });
  }

  getCampaignsUserFilter(userId: number, name: string): Observable<ResponseCampaign>{
    let headers = this.getHeaders();
    return this.http.get<ResponseCampaign>(`${ this._baseApi }/api/v1/campaigns-user-filter?user_id=${userId}&name=${name}`, { headers });
  }
}
